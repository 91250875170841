import React from 'react';
import Layout from '../components/layout';
import { login, isAuthenticated, getProfile } from '../utils/auth';

const Account = () => {
  if (!isAuthenticated()) {
    login();
    return (
      <Layout>
        <div className="text-center py-3">
          <h2>Redirecting to login...</h2>
        </div>
      </Layout>
    );
  }

  const user = getProfile();

  return (
    <>
      <Layout user={user}>
        <div className="content_wrap">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center py-3">
                  <h2>
                    Welcome to the Dashboard{' '}
                    <small>
                      <i>Beta</i>
                    </small>
                  </h2>
                  <h4>
                    New features will be added shortly such as payment for your
                    subscription and support.
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h5>
                  <strong>User Info</strong>
                </h5>
              </div>
              <div className="col-md-12">
                <strong>Email:</strong> {user.name}
              </div>
              <div className="col-md-12">
                <strong>Verified:</strong>{' '}
                {user.email_verified
                  ? 'Verified! Check back soon.'
                  : 'Please verify your email to continue in the future.'}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Account;
